import { createAction, createFeatureSelector, createSelector, props } from '@ngrx/store';
import { IRole } from '../../shared/models/role.interface';
import { PermissionState, RoleState } from './roles-and-permissions.state';
import { IPermission } from '../../shared/models/permission.interface';
import { IApplication } from '../../shared/models/aplications.interface';

const PREFIX = '[ROLES AND PERMISSIONS]';

export enum ActionTypes {
  DELETE_ROLE = `${PREFIX} DELETE ROLE`,
  CREATE_ROLE = `${PREFIX} CREATE ROLE`,
  UPDATE_ROLE = `${PREFIX} UPDATE ROLE`,
  LOAD_ROLES_LIST = `${PREFIX} LOAD ROLES LIST`,
  LOAD_ONLY_ROLES_LIST = `${PREFIX} LOAD ONLY ROLES LIST`,
  POPULATE_ROLES_LIST = `${PREFIX} POPULATE ROLES LIST`,
  GET_ROLE_BY_ID = `${PREFIX} GET ROLE BY ID`,
  POPULATE_CURRENT_ROLE = `${PREFIX} POPULATE CURRENT ROLE`,
  POPULATE_CURRENT_ROLE_SNAPSHOT = `${PREFIX} POPULATE CURRENT ROLE SNAPSHOT`,
  UPDATE_ROLE_FIELD = `${PREFIX} UPDATE ROLE FIELD`,
  PENDING_CREATE_UPDATE_ROLE = `${PREFIX} PENDING CREATE ROLE`,
  DISPATCHED_CREATE_UPDATE_ROLE = `${PREFIX} DISPATCHED CREATE ROLE`,
  SUCCESS_CREATE_UPDATE_ROLE = `${PREFIX} SUCCESS CREATE ROLE`,
  REMOVE_ROLE_FROM_LIST = `${PREFIX} REMOVE ROLE FROM LIST`,
  ADD_ROLE_TO_LIST = `${PREFIX} ADD ROLE TO LIST`,
  UPDATE_ROLE_FROM_LIST = `${PREFIX} UPDATE ROLE TO LIST`,
  RESET_CURRENT_ROLE = `${PREFIX} RESET_CURRENT_ROLE`,
  CHECK_ROLE_FOR_PENDING_CHANGES = `${PREFIX} CHECK CURRENT ROLE FOR PENDING CHANGES`,
  GET_APPLICATIONS = `${PREFIX} GET APPLICATIONS`,
  POPULATE_APPLICATIONS = `${PREFIX} POPULATE APPLICATIONS`,
  SET_ACTIVE_APPLICATION = `${PREFIX} SET ACTIVE APPLICATION`,
  SET_ACTIVE_APPLICATION_BY_ID = `${PREFIX} SET ACTIVE APPLICATION BY ID`,
  RESET_ACTIVE_APPLICATION = `${PREFIX} RESET ACTIVE APPLICATION`,
  LOAD_PERMISSIONS_LIST = `${PREFIX} LOAD PERMISSIONS LIST`,
  LOAD_ROLE_PERMISSIONS_LIST = `${PREFIX} LOAD ROLE PERMISSIONS LIST`,
  POPULATE_PERMISSIONS_LIST = `${PREFIX} POPULATE PERMISSIONS LIST`,
  POPULATE_ROLE_PERMISSIONS_LIST = `${PREFIX} POPULATE ROLE PERMISSIONS LIST`,
  RESET_PERMISSIONS_STATE = `${PREFIX} RESET PERMISSIONS STATE`
}


// export const populateRecordForms = createAction(ActionTypes.POPULATE_RECORD_FORMS, props<{
//   recordForms: RecordFormsModel[]
// }>());
//
// export const loadProjectList = createAction(ActionTypes.LOAPROJECTDLIST);
//
// export const updateProjectList = createAction(ActionTypes.UPDATEPROJECTLIST, props<{ projectList: ProjectModel[] }>());
//
// export const deleteProject = createAction(ActionTypes.DELETEPROJECT, props<{ id: number }>());
export const resetCurrentRole = createAction(ActionTypes.RESET_CURRENT_ROLE);
//
export const deleteRole = createAction(ActionTypes.DELETE_ROLE, props<{ id: string }>());
export const removeRoleFromList = createAction(ActionTypes.REMOVE_ROLE_FROM_LIST, props<{ id: string }>());
export const addRoleToList = createAction(ActionTypes.ADD_ROLE_TO_LIST, props<{ role: IRole }>());
export const updateRoleFromList = createAction(ActionTypes.UPDATE_ROLE_FROM_LIST, props<{ role: IRole }>());
//
export const loadRolesList = createAction(ActionTypes.LOAD_ROLES_LIST, props<{ serverIdentifier: string }>());
export const loadOnlyRolesList = createAction(ActionTypes.LOAD_ONLY_ROLES_LIST);

export const getApplications = createAction(ActionTypes.GET_APPLICATIONS, (defaultById?: string) => ({ defaultById }));
//
// export const refreshCurrentProject = createAction(ActionTypes.REFRESH_CURRENT_PROJECT);
//
export const createRole = createAction(ActionTypes.CREATE_ROLE, props<{
  role: Partial<IRole>,
}>());
export const updateRole = createAction(ActionTypes.UPDATE_ROLE, props<{
  role: Partial<IRole>,
}>());
export const populateCurrentRole = createAction(ActionTypes.POPULATE_CURRENT_ROLE, props<{
  role: IRole,
}>());
export const populateCurrentRoleSnapshot = createAction(ActionTypes.POPULATE_CURRENT_ROLE_SNAPSHOT, props<{
  role: IRole,
}>());
export const populateRolesList = createAction(ActionTypes.POPULATE_ROLES_LIST, props<{
  roles: IRole[],
}>());
export const getRoleById = createAction(ActionTypes.GET_ROLE_BY_ID, props<{
  id: string
}>());

export const populateApplications = createAction(ActionTypes.POPULATE_APPLICATIONS, props<{
  applications: IApplication[],
}>());

export const setActiveApplication = createAction(ActionTypes.SET_ACTIVE_APPLICATION, props<{
  application: IApplication,
}>());

export const setActiveApplicationById = createAction(ActionTypes.SET_ACTIVE_APPLICATION_BY_ID, props<{
  id: string,
}>());
export const resetActiveApplication = createAction(ActionTypes.RESET_ACTIVE_APPLICATION);
//
// export const populateCurrentProjectSnapshot = createAction(
//   ActionTypes.POPULATE_CURRENT_PROJECT_SNAPSHOT,
//   props<{ project: ProjectModel }>()
// );
//
// export const searchProjectById = createAction(ActionTypes.SEARCH_PROJECT_BY_ID, props<{ id: number }>());
//
// export const createProject = createAction(ActionTypes.CREATE_PROJECT, props<{ project: ProjectModel }>());
//
// export const updateProject = createAction(ActionTypes.UPDATE_PROJECT, props<{ project: ProjectModel }>());
//
export const pendingCreateUpdateRole = createAction(ActionTypes.PENDING_CREATE_UPDATE_ROLE, props<{
  pending: boolean
}>());

export const dispatchedCreateUpdateRole = createAction(ActionTypes.DISPATCHED_CREATE_UPDATE_ROLE, props<{
  dispatched: boolean
}>());
//
export const successCreateUpdateRole = createAction(ActionTypes.SUCCESS_CREATE_UPDATE_ROLE, props<{
  success: boolean
}>());
//
// export const updateProjectInList = createAction(ActionTypes.UPDATE_PROJECT_IN_LIST, props<{ project: ProjectModel }>());
//
// export const addProject = createAction(ActionTypes.ADD_PROJECT, props<{ project: ProjectModel }>());
//
export const updateRoleField = createAction(ActionTypes.UPDATE_ROLE_FIELD, props<{
  field: string,
  value: string | number | any
}>());
//
// export const updateProjectFields = createAction(ActionTypes.UPDATE_PROJECT_FIELDS, props<{
//   partialProject: Partial<ProjectModel>
// }>());
//
export const checkCurrentRoleForPendingChanges = createAction(
  ActionTypes.CHECK_ROLE_FOR_PENDING_CHANGES,
  props<{ hasChanges: boolean }>()
);

export const loadPermissionsList = createAction(ActionTypes.LOAD_PERMISSIONS_LIST, props<{
  serverIdentifier: string
}>());
export const loadRolePermissionsList = createAction(ActionTypes.LOAD_ROLE_PERMISSIONS_LIST, props<{
  serverIdentifier: string,
  studyName?: string
}>());

export const resetPermissionsState = createAction(ActionTypes.RESET_PERMISSIONS_STATE);

export const populatePermissionsList = createAction(ActionTypes.POPULATE_PERMISSIONS_LIST, props<{
  permissions: IPermission[],
}>());

export const populateRolePermissionsList = createAction(ActionTypes.POPULATE_ROLE_PERMISSIONS_LIST, props<{
  roles: IRole[],
}>());
//
export const selectRolesFeature = createFeatureSelector<RoleState>('roles');

export const selectRolesList = createSelector(
  selectRolesFeature,
  (state: RoleState) => state.list,
);

export const selectCurrentRole = createSelector(
  selectRolesFeature,
  (state: RoleState) => state.current
);

export const selectApplicationsList = createSelector(
  selectRolesFeature,
  (state: RoleState) => state.applications
);

export const selectActiveApplication = createSelector(
  selectRolesFeature,
  (state: RoleState) => state.activeApplication
);

export const selectPermissionsState = createSelector(
  selectRolesFeature,
  (state: RoleState) => state.permissions,
);

export const selectPermissionList = createSelector(selectPermissionsState, (state: PermissionState) => state.list);
export const selectRolePermissionsList = createSelector(selectPermissionsState, (state: PermissionState) => state.rolePermissions);

//
// export const checkCurrentProjectLockedState = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.isLocked
// );
// export const selectCurrentProjectManageProject = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.project
// );
//
// export const selectCurrentProjectManageProjectSnapshot = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.projectSnapshot
// );
//
